<template>
    <div style="width: 100%;height: 100%" v-loading="loading">
        <!--        <iframe width="100%" height="100%" src="https://continue-2000.github.io/Echarts-visualization-display-board/"></iframe>-->
        <div style="display: flex;justify-content: space-between;padding: 200px 300px">
            <div class="sy">
                <div>
                    <div style="font-size: 30px;font-weight: bold;text-align: center">
                        总充值
                    </div>
                    <div style="text-align: center;padding-bottom: 100px;padding-top: 20px">
                        {{detail.totalChongZhiAll?detail.totalChongZhiAll.toFixed(2):0.00}}
                    </div>
                </div>
            </div>

            <div class="sy">
                <div>
                    <div style="font-size: 30px;font-weight: bold;text-align: center">
                        总提现
                    </div>
                    <div style="text-align: center;padding-bottom: 100px;padding-top: 20px">
                        {{detail.totalTiXianAll?detail.totalTiXianAll.toFixed(2):0.00}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Data",
        data() {
            return {
                loading: false,
                detail: {}
            }
        },
        created() {
            this.findData();
        },
        methods: {
            findData() {
                this.loading = true;
                this.axios.get('/system/findData').then(resp => {
                    if (resp.data.code == 200) {
                        this.detail = resp.data.data;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>
    .sy {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        width: 400px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
